<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th colspan="2">
            {{ subtitle2 }}
          </b-th>
        </b-tr>
        <b-tr>
          <b-th />
          <b-th>1. Yıl Toplamı</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th colspan="2">
            Finansman İhtiyacı
          </b-th>
        </b-tr>
        <b-tr>
          <b-td>1. Başlangıç Yatırımı</b-td>
          <b-td class="text-right">
            {{ tableData.baslangic_yatirimi | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>2. İşletme Sermayesi</b-td>
          <b-td class="text-right">
            {{ tableData.isletme_sermayesi | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Toplam Finansman İhtiyacı</b-th>
          <b-td class="text-right">
            {{ tableData.toplam_finansman_ihtiyaci | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="2">
            Finansman Kaynakları
          </b-th>
        </b-tr>
        <b-tr>
          <b-td>1. Özkaynaklar</b-td>
          <b-td class="text-right">
            {{ tableData.ozkaynaklar | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>2. Hibe (Kosgeb - Tübikat vb)</b-td>
          <b-td class="text-right">
            {{ tableData.hibe | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>3. Yatırım - Borç</b-td>
          <b-td class="text-right">
            {{ tableData.yatirim_borc | toCurrency }} ₺
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th class="align-middle">
            Toplam Finansman
          </b-th>
          <b-th class="text-right">
            {{ tableData.toplam_finansman | toCurrency }} ₺
          </b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  name: 'PlanTable4',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  props: {
    subtitle2: {
      type: String,
      required: false,
      default: null,
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
