<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
      fixed
    >
      <b-thead>
        <b-tr>
          <b-th>{{ subtitle2 }}</b-th>
          <b-th class="text-right">
            1. Yıl Satış Fiyatı
          </b-th>
          <b-th class="text-right">
            1. Yıl Satış Miktarı
          </b-th>
          <b-th class="text-right">
            2. Yıl Satış Fiyatı
          </b-th>
          <b-th class="text-right">
            2. Yıl Satış Miktarı
          </b-th>
          <b-th class="text-right">
            3. Yıl Satış Fiyatı
          </b-th>
          <b-th class="text-right">
            3. Yıl Satış Miktarı
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item,key) in tableData"
          :key="key"
        >
          <b-td class="pl-1 pr-1">
            {{ item.y1_title }}
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ item.y1_sale_price | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ item.y1_sale_quantity | toCurrency }}
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ item.y2_sale_price | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ item.y2_sale_quantity | toCurrency }}
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ item.y3_sale_price | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ item.y3_sale_quantity | toCurrency }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  name: 'PlanTable3',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    subtitle2: {
      type: String,
      required: false,
      default: null,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style scoped></style>
