<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
      fixed
    >
      <b-thead>
        <b-tr>
          <b-th class="align-middle font-small-2">
            {{ subtitle2 }}
          </b-th>
          <b-th class="align-middle text-right font-small-2 text-primary">
            1. YIL AYLIK ORTALAMA GİDELER
          </b-th>
          <b-th class="align-middle text-right font-small-2 text-primary">
            2. YIL AYLIK ORTALAMA GİDELER
          </b-th>
          <b-th class="align-middle text-right font-small-2 text-primary">
            3. YIL AYLIK ORTALAMA GİDELER
          </b-th>
          <b-th class="align-middle text-right font-small-2 text-primary">
            1. Yıl Toplam Harcama
          </b-th>
          <b-th class="align-middle text-right font-small-2 text-primary">
            2. Yıl Toplam Harcama
          </b-th>
          <b-th class="align-middle text-right font-small-2 text-primary">
            3. Yıl Toplam Harcama
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody class="font-small-2">
        <b-tr>
          <b-th>Hammade - Malzeme Maliyeti</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_hammade_maliyeti | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_hammade_maliyeti | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_hammade_maliyeti | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_hammade_maliyeti_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_hammade_maliyeti_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_hammade_maliyeti_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Kira</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_kira | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_kira | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_kira | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_kira_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_kira_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_kira_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Elektrik Su Yemek vb</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_elektrik_su_yemek | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_elektrik_su_yemek | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_elektrik_su_yemek | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_elektrik_su_yemek_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_elektrik_su_yemek_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_elektrik_su_yemek_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Pazarlama Tanıtım</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_pazarlama_tanitim | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_pazarlama_tanitim | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_pazarlama_tanitim | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_pazarlama_tanitim_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_pazarlama_tanitim_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_pazarlama_tanitim_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Haberleşme</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_haberlesme | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_haberlesme | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_haberlesme | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_haberlesme_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_haberlesme_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_haberlesme_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Yakıt</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_yakit | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_yakit | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_yakit | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_yakit_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_yakit_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_yakit_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Personel (12 Aylık)</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_personel_12aylik | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_personel_12aylik | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_personel_12aylik | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_personel_12aylik_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_personel_12aylik_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_personel_12aylik_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>AR-GE ve Hizmet Alımları</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_arge_hizmet_alimlari | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_arge_hizmet_alimlari | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_arge_hizmet_alimlari | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_arge_hizmet_alimlari_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_arge_hizmet_alimlari_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_arge_hizmet_alimlari_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Genel Giderler</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_genel_giderler | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_genel_giderler | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_genel_giderler | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_genel_giderler_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_genel_giderler_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_genel_giderler_total | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>Beklenmeyen Giderler</b-th>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_beklenmeyen_giderler | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_beklenmeyen_giderler | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_beklenmeyen_giderler | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y1_beklenmeyen_giderler_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y2_beklenmeyen_giderler_total | toCurrency }} ₺
          </b-td>
          <b-td class="pl-1 pr-1 text-right">
            {{ tableData.y3_beklenmeyen_giderler_total | toCurrency }} ₺
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-th>Toplam</b-th>
          <b-th>{{ tableData.y1_toplam | toCurrency }} ₺</b-th>
          <b-th>{{ tableData.y2_toplam | toCurrency }} ₺</b-th>
          <b-th>{{ tableData.y3_toplam | toCurrency }} ₺</b-th>
          <b-th>{{ tableData.y1_toplam_total | toCurrency }} ₺</b-th>
          <b-th>{{ tableData.y2_toplam_total | toCurrency }} ₺</b-th>
          <b-th>{{ tableData.y3_toplam_total | toCurrency }} ₺</b-th>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  name: 'PlanTable1',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  props: {
    subtitle2: {
      type: String,
      required: false,
      default: null,
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
