<template>
  <div class="mb-2">
    <b-table-simple
      hover
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th>{{ subtitle2 }}</b-th>
          <b-th class="text-right">
            1. Yıl
          </b-th>
          <b-th class="text-right">
            2. Yıl
          </b-th>
          <b-th class="text-right">
            3. Yıl
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>Sabit Yatırım Toplamı</b-td>
          <b-td class="text-right">
            {{ tableData.y1_sabit_yatirim | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y2_gsabit_yatirim | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y3_gsabit_yatirim | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>İşletme Sermayesi</b-td>
          <b-td class="text-right">
            {{ tableData.y1_isletme_sermayesi | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y2_isletme_sermayesi | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y3_isletme_sermayesi | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Borç Kredi Faiz Ödemeleri</b-td>
          <b-td class="text-right">
            {{ tableData.y1_borc_kredi_faiz | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y2_borc_kredi_faiz | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y3_borc_kredi_faiz | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Borç Kredi Ana Para Ödemeleri</b-td>
          <b-td class="text-right">
            {{ tableData.y1_borc_kredi_anapara | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y2_borc_kredi_anapara | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y3_borc_kredi_anapara | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Vergiler</b-td>
          <b-td class="text-right">
            {{ tableData.y1_vergiler | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y2_vergiler | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y3_vergiler | toCurrency }} ₺
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td>Nakit Çıkışları Toplamı</b-td>
          <b-td class="text-right">
            {{ tableData.y1_nakit_cikis_toplam | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y2_nakit_cikis_toplam | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y3_nakit_cikis_toplam | toCurrency }} ₺
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Yıl Sonu Eldeki Nakit</b-td>
          <b-td class="text-right">
            {{ tableData.y1_yilsonu_nakit | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y2_yilsonu_nakit | toCurrency }} ₺
          </b-td>
          <b-td class="text-right">
            {{ tableData.y3_yilsonu_nakit | toCurrency }} ₺
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTbody,
  BTfoot,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

export default {
  name: 'PlanTable6',
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  props: {
    subtitle2: {
      type: String,
      required: false,
      default: null,
    },
    tableData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped></style>
